<template>
  <NConfigProvider :theme="theme" :theme-overrides="overrides">
    <component :is="themeEditorComponent">
      <slot />
    </component>
  </NConfigProvider>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  NConfigProvider,
  darkTheme,
  type GlobalThemeOverrides,
  NThemeEditor,
} from 'naive-ui'

import { useColorMode } from '@/composables/useColorMode'
import { useFeatureFlag } from '@/composables/useFeatureFlag'

const colorMode = useColorMode()
const themeEditorEnabled = useFeatureFlag('themeEditor')

const overrides = computed<GlobalThemeOverrides>(() => {
  return colorMode.value === 'dark'
    ? // dark mode overrides
      {}
    : // light mode overrides
      {}
})

const theme = computed(() => {
  return colorMode.value === 'dark' ? darkTheme : undefined
})

const themeEditorComponent = computed(() => {
  return themeEditorEnabled.value ? NThemeEditor : 'div'
})
</script>
