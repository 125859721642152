<template>
  <NModal
    v-if="enabled"
    v-model:show="show"
    preset="card"
    transform-origin="center"
    class="w-full max-w-xl"
  >
    <template #header>
      <NFlex size="small" align="center">
        <NIcon>
          <VtIcon name="sparkles" />
        </NIcon>
        {{ t('components.WhatsNewDialog.title') }}
      </NFlex>
    </template>
    <img
      v-if="'imgSrc' in currentItem"
      :src="currentItem.imgSrc"
      :alt="currentItem.imgAlt"
      class="h-80 max-h-80 w-full rounded object-contain"
    />
    <NH4 depth="2">{{ currentItem.title }}</NH4>
    <NP>
      {{ currentItem.content }}
    </NP>
    <template #footer>
      <NFlex align="center" justify="space-between">
        <NFlex size="small" align="center">
          <!-- Disabled until https://github.com/visiontree-software/mfx-framework/issues/2098 -->
          <NText v-show="false && unseenItems.length > 1" depth="3">
            {{
              t('components.WhatsNewDialog.pageCount', {
                current: currentItemIndex + 1,
                total: unseenItems.length,
              })
            }}
          </NText>
          <NButton
            v-show="unseenItems.length > 1"
            tertiary
            attr-type="button"
            :disabled="unseenItems.length === currentItemIndex + 1"
            @click="show = false"
          >
            {{ t('components.WhatsNewDialog.skipButton') }}
          </NButton>
        </NFlex>

        <NFlex size="small" align="center">
          <NButton
            v-show="unseenItems.length > 1"
            tertiary
            :disabled="currentItemIndex === 0"
            @click="back"
          >
            {{ t('components.WhatsNewDialog.backButton') }}
          </NButton>
          <NButton type="primary" @click="nextOrClose">
            {{
              currentItemIndex + 1 === unseenItems.length
                ? t('components.WhatsNewDialog.closeButton')
                : t('components.WhatsNewDialog.nextButton')
            }}
          </NButton>
        </NFlex>
      </NFlex>
    </template>
  </NModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { NButton, NModal, NFlex, NH4, NP, NText, NIcon } from 'naive-ui'

import { useI18n } from '#i18n'
import { useFeatureFlags, useFeatureFlag, useRoute, watch } from '#imports'

import { VtIcon } from '@visiontree/vue-ui'

// eslint-disable-next-line @typescript-eslint/unbound-method -- 3rd part library, no control
const { t, tm } = useI18n()
const route = useRoute()
const flags = useFeatureFlags()
const enabled = useFeatureFlag('whatsNewDialog')

const storageKey = 'mfx-studio:seenUpdates'

const isRecordOfStringArrays = (
  subject: unknown,
): subject is Record<string, string[]> => {
  return (
    !!subject &&
    typeof subject === 'object' &&
    Object.entries(subject).every(([key, value]) => {
      return typeof key === 'string' && Array.isArray(value)
    })
  )
}

const viewedUpdates = computed(() => {
  let parsed: unknown = {}
  const routeName = String(route.name)

  try {
    parsed = JSON.parse(window.localStorage.getItem(storageKey) || '')
  } catch (error) {
    return []
  }

  if (!isRecordOfStringArrays(parsed) || !(routeName in parsed)) {
    return []
  }

  return parsed[routeName]
})

interface Item {
  id: string
  title: string
  content: string
  flags?: string[]
}

interface ItemWithImage extends Item {
  imgSrc: string
  imgAlt: string
}

type UpdateItem = Item | ItemWithImage

const isArrayOfUpdateItems = (subject: unknown): subject is UpdateItem[] => {
  if (!subject || !Array.isArray(subject)) {
    return false
  }

  return subject.every((item: unknown): item is UpdateItem => {
    const isObject = !!item && typeof item === 'object'
    if (!isObject) {
      return false
    }
    return (
      !!item &&
      typeof item === 'object' &&
      // has an id property
      'id' in item &&
      typeof item.id === 'string' &&
      // has a title property
      'title' in item &&
      typeof item.title === 'string' &&
      // has a content property
      'content' in item &&
      typeof item.content === 'string' &&
      // maybe has an array of feature flags
      (!('flags' in item) || ('flags' in item && Array.isArray(item.flags)))
    )
  })
}

const unseenItems = computed(() => {
  const items = tm(
    ['components', 'WhatsNewDialog', 'pages', route.name].join('.'),
  )

  if (!isArrayOfUpdateItems(items)) {
    return []
  }

  return items.filter((item) => {
    const notSeenYet = !viewedUpdates.value.includes(item.id)
    const allFlagsEnabled = (item.flags || []).every((flag) => {
      return (
        flag in flags.value && flags.value[flag as keyof typeof flags.value]
      )
    })

    return notSeenYet && allFlagsEnabled
  })
})

const show = ref<boolean>(false)

watch(
  unseenItems,
  (items) => {
    if (items.length && !show.value) {
      show.value = true
    }
  },
  { immediate: true },
)

const currentItemIndex = ref<number>(0)

const currentItem = computed(() => {
  return unseenItems.value[currentItemIndex.value] || {}
})

const back = () => {
  currentItemIndex.value = Math.max(0, currentItemIndex.value - 1)
}

const nextOrClose = () => {
  if (currentItemIndex.value + 1 < unseenItems.value.length) {
    currentItemIndex.value = currentItemIndex.value + 1

    return
  }

  show.value = false
}
const markAllAsViewed = () => {
  const routeName = String(route.name)
  const newlySeenUpdates = unseenItems.value.map((item) => item.id)
  const previouslySeenUpdates = viewedUpdates.value

  let parsed: unknown
  let record: Record<string, string[]> = {}

  try {
    parsed = JSON.parse(window.localStorage.getItem(storageKey) || '')

    if (isRecordOfStringArrays(parsed)) {
      record = parsed
    }
  } catch (error) {
    // start over
  }

  record[routeName] = [...previouslySeenUpdates, ...newlySeenUpdates]

  window.localStorage.setItem(storageKey, JSON.stringify(record))
}

watch(show, (current, previous) => {
  if (previous && !current) {
    markAllAsViewed()
  }
})
</script>
